import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisGiftPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisGiftPage {
        nodes {
          entry {
            cta_cards {
              card_one_description
              card_one_headline {
                html
              }
              card_one_link_label
              card_one_link_to
              card_two_description
              card_two_headline {
                html
              }
              card_two_link_label
              card_two_link_to
            }
            header {
              description
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                  }
                }
              }
            }
            impressions {
              description
              headline {
                html
              }
              image_one {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                  }
                }
              }
              image_three {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                  }
                }
              }
              image_two {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
