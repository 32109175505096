import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer } from 'components';

// Hooks
import { useKisGiftPageData } from 'boxenStopHooks/useKisGiftPageData';

import {
  useGiftPageData,
  Header,
  Impressions,
  GiftBaskets,
  CtaCards
} from 'sections/giftPage';

const Gift = () => {
  const { seo } = useGiftPageData();

  const {
    header: kisHeader,
    impressions: kisImpressions,
    cta_cards: kisCtaCards
  } = useKisGiftPageData();

  return (
    <>
      <SEO {...seo} />
      <Spacer
        height={['2.5rem', '4rem', '3.1rem', '3.9rem', '7.1rem', '9.2rem']}
      />
      <Header data={kisHeader} />
      <Spacer
        height={['14rem', '14rem', '14.9rem', '10rem', '24.5rem', '22rem']}
      />
      <Impressions data={kisImpressions} />
      <Spacer
        height={['14rem', '18.5rem', '20.6rem', '12rem', '20rem', '20rem']}
      />
      <GiftBaskets />
      <Spacer
        height={[
          '21rem',
          '21.3rem',
          '26.1rem',
          '27.5rem',
          '27.6rem',
          '25.2rem'
        ]}
      />
      <CtaCards data={kisCtaCards} />
      <Spacer
        height={[
          '39.5rem',
          '35.5rem',
          '29.2rem',
          '23.9rem',
          '27.6rem',
          '25.2rem'
        ]}
      />
    </>
  );
};

export default injectIntl(Gift);
